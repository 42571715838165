const categories = [
  {
    id: 0,
    name: "Allgemein",
  },
  {
    id: 1,
    name: "News",
  },
]

exports.categories = categories
