import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { categories as categoriesConfig } from "../../helpers/categories"

function Page({ data }) {
  const { allMdx } = data
  const { edges } = allMdx
  const unorderedCategorieList = {}
  const categorieList = {}
  let postCounter = 0

  edges.forEach(edge => {
    const { node } = edge
    const { frontmatter } = node
    const { categories } = frontmatter
    postCounter++;

    for (const category of categories) {
        if (!unorderedCategorieList[categoriesConfig[category].name]) {
            unorderedCategorieList[categoriesConfig[category].name] = [];
        }

        const categoryList = unorderedCategorieList[categoriesConfig[category].name];
        
        if (categoryList.some(thisNode => thisNode.id === node.id)) {
            continue;
        }
        
        categoryList.push(node)
    }
  });

  Object.keys(unorderedCategorieList)
    .sort()
    .forEach(key => {
      categorieList[key] = unorderedCategorieList[key]
    })

  return (
    <Layout title="Tags">
      <SEO title="Tags" />

      <p>
        Alle Blogbeiträge sind mit einem Tag oder auch Schlagwort versehen.
        Diese Seite listet alle Schlagworte samt deren Beiträge auf.
      </p>
      <p>
        Insgesamt wurden <strong>{postCounter}</strong> Beiträge auf diesem Blog
        veröffentlicht.
      </p>

      <hr className="my-4" />

      {Object.keys(categorieList).map(key => (
        <div key={key} className="mb-4">
          <h2 className="h4">{key}</h2>

          <ul>
            {categorieList[key].map(node => {
              const { fields, frontmatter } = node

              return (
                <li key={node.id}>
                  <Link to={fields.slug}>{frontmatter.title}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      ))}
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(filter: { frontmatter: { categories: { gte: 0 } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            categories
            title
          }
        }
      }
    }
  }
`

export default Page
